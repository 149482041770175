import { graphql } from 'gatsby'
import React from 'react'
import { Divider } from '../../components/Divider'
import { MagazineContainer } from '../../components/MagazineContainer'
import { MagazineSingleTop } from '../../components/MagazineSingleTop'
import { PageContent } from '../../components/PageContent'
import { SPACING } from '../../helpers/constants'
import { parseSeo } from '../../helpers/seo'
import { usePageData } from '../../helpers/utilities'
import { WpBlocksGenerator } from '../../helpers/wpBlocksGenerator'
import Layout from '../../views/Layout'
import MagazineHeader from '../../views/MagazineHeader'
import SEO from '../../views/Seo'

export default ({ data, pageContext }) => {
  const { post } = usePageData(data, pageContext)

  const { blocks = [], seo, title } = post

  const image =
    post && post.featuredImage && post.featuredImage.node
      ? post.featuredImage.node.makeImageLarge
      : null

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <MagazineHeader />
      <MagazineContainer>
        <PageContent>
          <MagazineSingleTop
            title={title ? title : ''}
            image={image ? image : ''}
          />

          <WpBlocksGenerator blocks={blocks} post={post} />

          <Divider
            size={{
              desktop: SPACING.MEDIUM,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />
        </PageContent>
      </MagazineContainer>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      post(id: $id) {
        ...Single_Post
      }
      ## stickyPosts and posts comes from gatsby-node or preview
    }
  }
`
