import React from 'react'
import PropTypes from 'prop-types'
import StyledMagazineHeaderMobile from './style'
import { OverflowHiddenStyle } from '../../styles/base/overflowHiddenStyle'
import { getUrl } from '../../helpers/url'
import { isInternal } from '../../helpers/link'
import { Row } from '../../components/Row'
import { Button } from '../../components/Button'
import { Heading } from '../../components/Heading'

const MagazineHeaderMobile = props => {
  const { open, menuItems, handleClose } = props

  return (
    <StyledMagazineHeaderMobile {...props}>
      {open && <OverflowHiddenStyle />}
      <StyledMagazineHeaderMobile.Content>
        <Row>
          <Heading type={'h3'} size={3}>
            Ecoride Magasin
          </Heading>
          <Button
            handleClick={handleClose}
            icon={'keyboard_arrow_down'}
            transparent
          />
        </Row>

        <StyledMagazineHeaderMobile.InnerContent open={open}>
          {menuItems.nav.map(item => {
            if (item.databaseId) {
              if (item.databaseId !== 1 && item.slug) {
                return (
                  <MagazineLink
                    key={item.id}
                    to={item.uri ? getUrl(item.uri) : ''}
                  >
                    {item.name}
                  </MagazineLink>
                )
              } else return null
            } else return null
          })}
        </StyledMagazineHeaderMobile.InnerContent>
      </StyledMagazineHeaderMobile.Content>
    </StyledMagazineHeaderMobile>
  )
}

MagazineHeaderMobile.propTypes = {
  open: PropTypes.bool,
  menuItems: PropTypes.object,
}

const MagazineLink = props => {
  const { children, to } = props
  const Component = isInternal(to)
    ? StyledMagazineHeaderMobile.Internal
    : StyledMagazineHeaderMobile.External
  return (
    <Component {...props}>
      <StyledMagazineHeaderMobile.NavItem>
        {children}
      </StyledMagazineHeaderMobile.NavItem>
    </Component>
  )
}

export { MagazineHeaderMobile }
