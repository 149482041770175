import { Link } from 'gatsby'
import styled from 'styled-components'
import StyledHeading from '../../components/Heading/style'
import StyledRow from '../../components/Row/style'
import {
  colors, displayNone, mq, spacing, typography
} from '../../styles/utilities/variables'

const StyledMagazineHeader = styled.div`
  // Hack to avoid bad behavior on mobile.
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  background: white;

  & ${StyledRow} {
    margin-bottom: ${spacing.xSmall};
  }

  & ${StyledHeading.h1} {
    font-weight: bold;
    font-size: 96px;
    color: ${colors.primaryRed};
    text-transform: uppercase;
    letter-spacing: 0.01em;
    margin-left: ${spacing.base};

    @media screen and (min-width: 768px) and (max-width: 870px) {
      font-size: 75px;
    }
  }

  & span {
    position: absolute;
    left: 8px;
    font-family: ${typography.secondaryFont};
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px;
    text-transform: uppercase;
    color: ${colors.primaryDarkBlue};
    transform: rotate(-90deg) translateX(6px);

    @media ${mq.tablet} {
      left: -3px;
    }

    @media screen and (min-width: 768px) and (max-width: 870px) {
      font-size: 15px;
    }
  }
`

StyledMagazineHeader.Desktop = styled.div`
  ${displayNone.mobile}
`

StyledMagazineHeader.PrimaryNav = styled.div`
  border-top: 1px solid ${colors.adaptedLightBlue};
  display: flex;
  justify-content: center;
`

StyledMagazineHeader.PrimaryNavLinks = styled.ul`
  display: flex;
  list-style: none;
  align-self: flex-end;
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  padding-top: ${spacing.medium};

  & li {
    margin: 0 ${spacing.small};

    &:first-child {
      margin-left: 0;
    }

    &${StyledMagazineHeader.Internal} {
      background-color: ${colors.adaptedLightBlueBackground};
      cursor: pointer;

      .active {
        background-color: ${colors.secondaryLightBlue};
      }

      a {
        padding: 1rem;

        &:hover {
          background-color: ${colors.secondaryLightBlue};
        }
      }
    }
  }
`

StyledMagazineHeader.SecondaryNav = styled.ul`
  list-style: none;
  margin-left: auto;
  align-self: flex-end;
  margin-bottom: 15px;
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};

  & li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: ${spacing.xSmall};
    }
  }

  svg {
    margin-right: ${spacing.xSmall};
  }
`

StyledMagazineHeader.NavItem = styled.li``

StyledMagazineHeader.Internal = styled(Link)`
  color: ${colors.primaryRed};
  display: inline-block;
`

StyledMagazineHeader.External = styled.a`
  margin: 0 ${spacing.small};
  color: ${colors.primaryRed};
  display: inline-block;
`

export default StyledMagazineHeader
