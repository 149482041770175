import PropTypes from 'prop-types'
import React from 'react'
import { SPACING } from '../../helpers/constants'
import { Container } from '../Container'
import { Divider } from '../Divider'
import { MagazineHeading } from '../MagazineHeading'
import { MakeImage } from '../MakeImage'
import StyledMagazineSingleTop from './style'

const MagazineSingleTop = props => {
  const { title, image } = props

  if (title) {
    return (
      <StyledMagazineSingleTop>
        <Container>
          <MagazineHeading size={1} type={'h1'} title={title} bold />
        </Container>
        <Divider
          size={{
            desktop: SPACING.MEDIUM,
            tablet: SPACING.SMALL,
            mobile: SPACING.SMALL,
          }}
        />
        {image && (
          <StyledMagazineSingleTop.ImageWrapper>
            <MakeImage makeImage={image} coverBackground />
          </StyledMagazineSingleTop.ImageWrapper>
        )}
      </StyledMagazineSingleTop>
    )
  }
}

MagazineSingleTop.propTypes = {
  children: PropTypes.string,
  title: PropTypes.string,
  categories: PropTypes.array,
  image: PropTypes.object,
}

export { MagazineSingleTop }
