import styled from 'styled-components'
/* Path below could differ */
import { mq } from '../../styles/utilities/variables'

const StyledMagazineContainer = styled.div`
  background: white;

  @media ${mq.mobile} {
    margin-top: 9rem;
  }
`

export default StyledMagazineContainer
