import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Container } from '../../components/Container'
import { Divider } from '../../components/Divider'
import { Heading } from '../../components/Heading'
import { Overlay } from '../../components/Overlay'
import { Row } from '../../components/Row'
import {
  SocialIconFacebook,
  SocialIconInstagram,
  SocialIconYoutube,
} from '../../components/Svg'
import { SPACING } from '../../helpers/constants'
import { isInternal } from '../../helpers/link'
import { getUrl } from '../../helpers/url'
import { useTranslation } from '../../hooks/useTranslation'
import { MagazineHeaderMobile } from '../MagazineHeaderMobile'
import { useMagazineHeaderQuery } from './hook'
import StyledMagazineHeader from './style'

const MagazineHeader = () => {
  const magazineHeaderSettings = useMagazineHeaderQuery()
  const [magazineHeaderMobileOpen, setMagazineHeaderMobileOpen] = useState(
    false
  )
  const handleMagazineHeaderMobileToggle = () => {
    setMagazineHeaderMobileOpen(!magazineHeaderMobileOpen)
  }

  const getTranslation = useTranslation()
  const location = useLocation()

  // @TODO DO A BETTER SOLUTION HERE PLX
  const getMagazinePath =
    magazineHeaderSettings.nav.length > 0 &&
    typeof magazineHeaderSettings.nav[0].uri !== 'undefined'
      ? `/${magazineHeaderSettings.nav[0].uri.split('/')[1]}/`
      : '/magasin/'

  return (
    <StyledMagazineHeader>
      <StyledMagazineHeader.Desktop open={magazineHeaderMobileOpen}>
        <Container>
          <Divider
            size={{
              desktop: SPACING.LARGE,
              tablet: SPACING.MEDIUM,
              mobile: SPACING.SMALL,
            }}
          />

          <Row justify={'flex-start'}>
            <span>Ecoride</span>
            <Heading type={'h1'} size={1}>
              <StyledMagazineHeader.Internal to={getMagazinePath}>
                {getTranslation('magasinText')}
              </StyledMagazineHeader.Internal>
            </Heading>

            <StyledMagazineHeader.SecondaryNav>
              {magazineHeaderSettings.socials.map((item, key) => {
                return (
                  <MagazineLink key={key} to={item.link}>
                    {(item.label.toLowerCase().includes('facebook') ||
                      item.label.toLowerCase().includes('fb')) && (
                      <SocialIconFacebook />
                    )}

                    {(item.label.toLowerCase().includes('youtube') ||
                      item.label.toLowerCase().includes('yt')) && (
                      <SocialIconYoutube />
                    )}

                    {(item.label.toLowerCase().includes('instagram') ||
                      item.label.includes('ig')) && <SocialIconInstagram />}
                    {item.label}
                  </MagazineLink>
                )
              })}
            </StyledMagazineHeader.SecondaryNav>
          </Row>
        </Container>

        <StyledMagazineHeader.PrimaryNav>
          <Container>
            <StyledMagazineHeader.PrimaryNavLinks>
              {magazineHeaderSettings.nav
                .filter(
                  item => item.databaseId && item.databaseId !== 1 && item.slug
                )
                .map(item => (
                  <MagazineLink
                    key={item.id}
                    to={item.uri ? getUrl(item.uri) : ''}
                    isActive={item.uri === location.pathname ? 1 : 0}
                  >
                    {item.name}
                  </MagazineLink>
                ))}
            </StyledMagazineHeader.PrimaryNavLinks>
          </Container>
        </StyledMagazineHeader.PrimaryNav>
      </StyledMagazineHeader.Desktop>

      <MagazineHeaderMobile
        open={magazineHeaderMobileOpen}
        menuItems={magazineHeaderSettings}
        handleClose={handleMagazineHeaderMobileToggle}
      />
      {magazineHeaderMobileOpen && (
        <Overlay handleClick={handleMagazineHeaderMobileToggle} />
      )}
    </StyledMagazineHeader>
  )
}

MagazineHeader.propTypes = {
  children: PropTypes.any,
}

const MagazineLink = props => {
  const { children, to, isActive } = props
  const checkIsInternal = isInternal(to)
  return (
    <StyledMagazineHeader.NavItem>
      {checkIsInternal ? (
        <StyledMagazineHeader.Internal
          {...props}
          className={isActive && 'active'}
        >
          {children}
        </StyledMagazineHeader.Internal>
      ) : (
        <StyledMagazineHeader.External target={'_blank'} href={to} {...props}>
          {children}
        </StyledMagazineHeader.External>
      )}
    </StyledMagazineHeader.NavItem>
  )
}

export default MagazineHeader
