import styled from 'styled-components'
import {
  colors,
  typography,
  spacing,
  mq,
} from '../../styles/utilities/variables'

const StyledMagazineSingleTop = styled.div`
  margin-bottom: ${spacing.xxLarge};
  h1 {
    font-family: ${typography.primaryFont};
    line-height: 1.15;
    max-width: 650px;
    padding: 0 3.6rem;
    font-weight: bold;
    span  {
      font-weight: normal;
    }
    @media ${mq.mobile} {
      font-size: ${typography.sizes.large};
    }
  }
`

StyledMagazineSingleTop.CategoryContainer = styled.div`
  max-width: 650px;
  padding: 0 3.6rem;
`

StyledMagazineSingleTop.Text = styled.div``
StyledMagazineSingleTop.ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${spacing.xxLarge};
  height: calc(100vh - 6.3rem);
  box-sizing: border-box;
  @media ${mq.mobile} {
    height: 40vh;
  }
`

StyledMagazineSingleTop.Category = styled.h6`
  color: ${colors.primaryRed};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: ${spacing.base};
`

export default StyledMagazineSingleTop
