import { useStaticQuery, graphql } from 'gatsby'

export const useMagazineHeaderQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query MagazineHeaderQuery($lang: WordPress_LanguageCodeFilterEnum) {
      wordpress {
        magazineHeaderSettings(language: $lang) {
          data {
            socialsColumn {
              label
              link
            }
          }
        }
        categories(first: 100, where: { language: $lang, hideEmpty: true }) {
          nodes {
            ...Single_Post_Category
          }
        }
      }
    }
  `)

  const { data: socialData } = staticQuery.wordpress.magazineHeaderSettings
  const { nodes: navData } = staticQuery.wordpress.categories

  const magazineMenu = {
    nav: navData,
    socials: socialData.socialsColumn,
  }

  return magazineMenu
}
