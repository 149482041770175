import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import {
  spacing,
  colors,
  mqSizes,
  typography,
} from '../../styles/utilities/variables'
import styledButton from '../../components/Button/style'

const StyledMagazineHeaderMobile = styled.div`
  background-color: white;
  z-index: 1002;
  position: fixed;
  width: 100%;
  transition: all 0.35s ease;
  @media (min-width: ${mqSizes.mobile}px) {
    display: none;
  }
  h3 {
    font-family: ${typography.primaryFont};
    line-height: 140%;
    letter-spacing: 0.03em;
  }
  button {
    padding-right: 0;
  }
  i {
    font-size: 40px;
  }

  & ${styledButton} {
    i {
      transform: rotate(${props => (props.open ? '180' : '0')}deg);
      transition: transform 0.35s ease;
    }
  }
`

StyledMagazineHeaderMobile.Content = styled.div`
  padding: ${spacing.medium};
  font-family: ${typography.primaryFont};
  & > div {
    margin-bottom: 0 !important;
  }
`

StyledMagazineHeaderMobile.InnerContent = styled(motion.ul)`
  list-style: none;
  display: flex;
  flex-direction: column;
  max-height: ${props => (props.open ? '100rem' : '0')};
  pointer-events: ${props => (props.open ? 'initial' : 'none')};
  opacity: ${props => (props.open ? '1' : '0')};
  transition: opacity 0.35s ease, max-height 0.35s ease;
  > a:first-child {
    padding-top: 2.2rem;
  }
`

StyledMagazineHeaderMobile.NavItem = styled.li`
  padding-top: ${spacing.small};
  padding-bottom: ${spacing.small};
`

StyledMagazineHeaderMobile.Internal = styled(Link)`
  color: ${colors.primaryDarkBlue};
  display: inline-block;
  line-height: 140%;
  letter-spacing: 0.03em;
  font-size: ${typography.sizes.medium};
`

StyledMagazineHeaderMobile.External = styled.a`
  margin: 0 ${spacing.small};
  color: ${colors.primaryRed};
  display: inline-block;
`

export default StyledMagazineHeaderMobile
